<template>
	<v-container>
		<div class="text-h3" style="margin:20px 0">Roadmap</div>
		<p>Her holder vi oversikt over milepæler vi har gjort, holder på med og har tenkt å gjøre.</p>
		<p>Har du forslag til hva vi burde ha med? Ta gjerne å se på hvordan du kan komme med forslag til <a href="https://nyheter.brukmat.no/onsker-du-a-bidra/">bidrag her</a></p>
		<v-divider></v-divider>
		<div class="text-h5" style="margin:20px 0">Ikonbeskrivelse</div>
		
		<v-row
			style="margin:20px 0 30px 0"
			align="center"
			justify="space-around"
		>
			<v-card style="padding:20px" dark color="green"><v-icon>mdi-check-bold</v-icon> Ferdig</v-card>

			<v-card style="padding:20px" dark color="blue lighten-1"><v-icon>mdi-clock-check</v-icon> Jobber med</v-card>

			<v-card style="padding:20px" dark color="purple darken-1"><v-icon>mdi-brain</v-icon> Planlegger</v-card>

			<v-card style="padding:20px" dark color="red lighten-2"><v-icon>mdi-beaker-question</v-icon> Vurderer/tester</v-card>
		</v-row>
		<v-divider></v-divider>
		<v-tabs background-color="transparent" flat>
			<v-tab>2022 ({{y2022.length}})</v-tab>
			<v-tab>2021 ({{y2021.length}})</v-tab>
			<v-tab>Forbi 2022 ({{items.length}})</v-tab>
			<v-tab-item>
				<v-card
				:color='$vuetify.theme.themes[($vuetify.theme.dark) ? "dark" : "light"].background'
				flat
				>
					<v-timeline
					align-top
					>
						<v-timeline-item
							v-for="(item, i) in y2022"
							:key="i"
							:color="item.color"
							:icon="item.icon"
							fill-dot
						><span slot="opposite"><strong>{{item.date}}</strong></span>
						<v-card
							:color="item.color"
							dark
						>
							<v-card-title class="title" style="word-break:normal">
								{{item.title}}
							</v-card-title>
							<v-card-text class="white text--primary" v-if="item.text">
								<p>{{item.text}}</p>
							</v-card-text>
						</v-card>
					</v-timeline-item>
				</v-timeline>
				</v-card>
			</v-tab-item>
			<v-tab-item>
				<v-card
				:color='$vuetify.theme.themes[($vuetify.theme.dark) ? "dark" : "light"].background'
				flat
				>
					<v-timeline
					align-top
					>
						<v-timeline-item
							v-for="(item, i) in y2021"
							:key="i"
							:color="item.color"
							:icon="item.icon"
							fill-dot
						>
							<span slot="opposite"><strong>{{item.date}}</strong></span>
							<v-card
								:color="item.color"
								dark
							>
								<v-card-title class="title" style="word-break:normal">
									{{item.title}}
								</v-card-title>
								<v-card-text class="white text--primary" v-if="item.text">
									<p>{{item.text}}</p>
								</v-card-text>
							</v-card>
						</v-timeline-item>
					</v-timeline>
				</v-card>
			</v-tab-item>
			<v-tab-item>
				<v-card
				:color='$vuetify.theme.themes[($vuetify.theme.dark) ? "dark" : "light"].background'
				flat
				>
					<v-timeline
					align-top
					>
						<v-timeline-item
							v-for="(item, i) in items"
							:key="i"
							:color="item.color"
							:icon="item.icon"
							fill-dot
						>
							<span slot="opposite"><strong>{{item.date}}</strong></span>
							<v-card
								:color="item.color"
								dark
							>
								<v-card-title class="title" style="word-break:normal">
									{{item.title}}
								</v-card-title>
								<v-card-text class="white text--primary" v-if="item.text">
									<p>{{item.text}}</p>
								</v-card-text>
							</v-card>
						</v-timeline-item>
					</v-timeline>
				</v-card>
			</v-tab-item>
		</v-tabs>
		
		
	</v-container>
</template>
<script>
let status = {
	DONE:{
		color:'green',
		status:"DONE",
		icon:'mdi-check-bold'
	},
	DOING:{
		status:"DOING",
		color:"blue lighten-1",
		icon:'mdi-clock-check'
	},
	PLANNING:{
		status:"PLANNING",
		color:"purple darken-1",
		icon:'mdi-brain'
	},
	FUTURE:{
		status:"FUTURE",
		color:'red lighten-2',
		icon:'mdi-beaker-question'
	}
};
let statusText = [
	{
		...status.DONE,
		title:"Importere ingredienser og næringsinnhold",
		date:"Mai, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Lage oversikt over næringsinnhold",
		date:"Mai, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Søkbare oppskrifter ut i fra ingredienser",
		date:"Mai, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Mulighet for å lage oppskrifter med framgangsmåter og ingredienser",
		date:"Mai, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Legge inn basis framgangsmåter på oppskrifter",
		date:"Mai, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Oversiktside for hver enkelte oppskrift",
		date:"Mai, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Oversiktside for hver enkelte ingrediens",
		date:"Mai, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Side for å kalkulere næringsinnhold fra flere ingredienser",
		date:"Mai-Juni, 2021",
		year:2021

	},
	{
		...status.DONE,
		title:"Legge inn mindre mengde med oppskrifter",
		date:"Mai-Juni, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Søking av ingredienser ved bruk av næringsinnhold",
		date:"Mai-Juni, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Generere/Lage oppskrifter",
		date:"Juni, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Mørkt tema",
		date:"Juli, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"'Smarte' oppskrifter i søkemotorer (hvis du søker på en oppskrift kommer det opp et eget 'kort' fra Brukmat)",
		date:"Juli, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Generere næringsinnhold på oppskrifter",
		date:"Juli, 2021",
		year:2021
	},
	{
		...status.DONE,
		title:"Omgjøring av infrastruktur, automatisere oppgaver relatert til utvikling av Brukmat",
		date:"Januar, 2022",
		year:2022
	},
	{
		...status.DOING,
		title:"Skrive om koden for bak systemer for bedre ytelse og stabilitet",
		date:"2022",
		year:2022
	},
	{
		...status.DOING,
		title:"Min Mat (innlogging/oppretting av brukere",
		date:"2022",
		year:2022
	},
	{
		...status.PLANNING,
		title:"La deg legge inn og publisere dine egne oppskrifter",
		date:"2022",
		year:2022
	},
	{
		...status.FUTURE,
		title:"Forslag for oppskrifter basert på hva som finnes i skapene hjemme hos deg",
		date:"2022-2023"
	},
	{
		...status.FUTURE,
		title:"'3D' framgangsmåter på oppskrifter",
		date:"Forbi 2022"
	}
].reverse();
let future = statusText.filter(status=>status.status == "FUTURE");
let y2021 = statusText.filter(status=>status.year == 2021);
let y2022 = statusText.filter(status=>status.year == 2022);
export default {
    metaInfo: {
        // override the parent template and just use the above title only
        title: 'Roadmap',
        // all titles will be injected into this template
        titleTemplate: '%s | Brukmat'
    },
	data: () => ({
		items:future,
		y2021:y2021,
		y2022:y2022
	}),
}
</script>